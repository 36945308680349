import React from 'react'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import {useTranslation} from 'react-i18next'

const NotFoundPage = () => {
  const {t} = useTranslation()
  return (
    <Layout>
      <SEO title="404: Not found" />
      <h1>{t('notFound')}</h1>
      <p>{t('notFoundText')}</p>
    </Layout>
  )
}
export default NotFoundPage
